import staticImageLoader from "@lib/staticImageLoader";
import { Box, Link as MuiLink, Typography } from "@mui/material";
import Layout from "layout/landingPage";
import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import errorImage from "public/image/error-404.jpg";

export default function Error(props) {
  return (
    <Layout>
      <Head>
        <title>Printy6 | Not Found</title>
      </Head>
      <Box
        style={{
          width: "100%",
          textAlign: "center",
          background: "#fff",
        }}
      >
        <Box
          sx={{
            background: "#ffffff",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              pt: 6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxWidth: 520,
              mx: "auto",
            }}
          >
            <Image src={errorImage} loader={staticImageLoader} />
          </Box>
          <Box
            sx={{
              mt: 2,
              mx: 2,
              mb: 4,
            }}
          >
            <Typography variant="h4">Oops! You're lost...</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              The page you are looking for
              <br />
              might have been moved, renamed,
              <br />
              or might never existed.
            </Typography>
            <Box sx={{ mt: 4 }}>
              <Link href="/" passHref>
                <MuiLink underline="none">
                  <Box
                    component="button"
                    sx={{
                      p: 0,
                      fontSize: 16,
                      lineHeight: "18px",
                      fontWeight: 600,
                      textDecoration: "none",
                      cursor: "pointer",
                      color: "#ffffff",
                      textTransform: "uppercase",
                      letterSpacing: "1px",
                      height: 48,
                      boxShadow: "none",
                      position: "relative",
                      borderRadius: "24px",
                      border: 0,
                      bgcolor: "primary.main",
                      // background: "#ff596f",
                      outline: "none",
                      display: "flex",
                      width: 200,
                      justifyContent: "center",
                      alignItems: "center",
                      mx: "auto",
                    }}
                  >
                    Back to Home
                  </Box>
                </MuiLink>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
